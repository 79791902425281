// WRITE ARTICLE
export const addFloorAreaMeasures = {
  order: 45,
  name: "Add floor area measures",
  keywords:
    "red ruler standardized sf square foot feet doors keys units spaces stalls MW mega watt",
  subcategory: "Properties",
  markdown: `# Add floor area measures

  ## Floor area with no size

  When a floor area has no standardized area, it will display a {% inlineAppIcon iconClass="fa-solid fa-ruler-horizontal text-red-600" /%} **red ruler** in its upper-left corner. The diagram will not display the relative width of each building level unless **every** floor area has a standardized area.`,
};
